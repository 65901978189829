import service from "@/common/request.js";
/**
 * 用户注册时发送短信
 * @param {*} param phone_number
 */
export const sms_register = (param) =>
  service.post("/auth/sms/register/", param);

/**
 * 创建用户，用于注册
 * @param {*} param phone_number
 * first_namet3333
姓名

username18659030967
用户名（手机号）

verification_code122333
验证码

password872222
密码

email233212@q.com
邮箱

company_name54345tfre
公司名称

position678ij
职位

attachment
附件（文件）

products1,2
产品权限，product_id 逗号分隔
 * 
 * 
 * 
 * 
 * 
 * 
 */
export const auth_users = (param) => service.post("/auth/users/", param);
// //  自激活注册
export const linkreg = (param) => service.post("/auth/users/linkreg/", param);

/**
 * 获取产品列表
 */
export const apiIter_products = () => service.get("/product/");

//  自激活效验
// export const linkchk = (param) => service.post("/auth/users/linkchk/", param);
